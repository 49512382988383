import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

const Logo = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg width="84mm" height="22mm" viewBox="0 0 84 22" version="1.1" id="svg-logo">
        <defs id="defs2" />
        <g id="layer1" transform="translate(0,-275)" fill={fill}>
          <g aria-label="RSØM Logo (React State of Mind)" transform="scale(0.99684738,1.0031626)" id="text18">
            <path d="m 0.95201062,295.77786 q -0.29900093,0 -0.53820168,-0.2093 -0.20930065,-0.2392 -0.20930065,-0.5382 V 275.6252 q 0,-0.3289 0.20930065,-0.5382 0.20930065,-0.2392 0.53820168,-0.2392 H 9.1745362 q 3.9468128,0 6.1594188,1.794 2.242507,1.79401 2.242507,5.05312 0,2.09301 -0.986703,3.55811 -0.956803,1.4651 -2.661108,2.27241 l 4.036513,7.29562 q 0.0897,0.1794 0.0897,0.3289 0,0.2392 -0.179401,0.4485 -0.1794,0.1794 -0.448501,0.1794 h -4.215913 q -0.867103,0 -1.225904,-0.8073 L 8.6961347,288.48224 H 5.8257258 v 6.54812 q 0,0.3289 -0.2392008,0.5382 -0.2093006,0.2093 -0.5382016,0.2093 z M 9.1446361,284.08693 q 1.2857039,0 1.9435059,-0.62791 0.687702,-0.6578 0.687702,-1.794 0,-1.1362 -0.687702,-1.82391 -0.657802,-0.6877 -1.9435059,-0.6877 H 5.8257258 v 4.93352 z"
              id="logo-r" />
            <path d="m 28.917942,296.07686 q -2.900309,0 -4.933515,-0.8372 -2.033206,-0.8372 -3.07971,-2.21261 -1.046503,-1.3754 -1.106303,-2.99001 0,-0.2691 0.1794,-0.4485 0.209301,-0.1794 0.478402,-0.1794 h 3.976712 q 0.358801,0 0.568102,0.1196 0.239201,0.1196 0.508302,0.4186 0.388701,0.71761 1.196003,1.19601 0.837203,0.4485 2.212607,0.4485 1.614605,0 2.481708,-0.4784 0.867103,-0.5083 0.867103,-1.37541 0,-0.6279 -0.448502,-1.0166 -0.448501,-0.4186 -1.435204,-0.7176 -0.986703,-0.3289 -2.840509,-0.7176 -3.617911,-0.71761 -5.411917,-2.18271 -1.794005,-1.46511 -1.794005,-4.18601 0,-1.85381 1.016603,-3.28901 1.046503,-1.46511 2.930209,-2.27241 1.913606,-0.8073 4.425214,-0.8073 2.631208,0 4.544814,0.9269 1.943506,0.897 2.960109,2.27241 1.046503,1.3455 1.106303,2.6611 0,0.26911 -0.1794,0.47841 -0.179401,0.1794 -0.448501,0.1794 h -4.186014 q -0.657802,0 -1.016603,-0.5382 -0.1794,-0.62791 -0.926903,-1.04651 -0.747502,-0.4485 -1.853805,-0.4485 -1.255804,0 -1.943506,0.4485 -0.687703,0.4485 -0.687703,1.31561 0,0.8671 0.897003,1.3754 0.897003,0.4784 3.348811,0.9867 2.810608,0.5083 4.455114,1.28571 1.674405,0.7475 2.451807,1.9734 0.807303,1.19601 0.807303,3.04981 0,2.03321 -1.166104,3.52821 -1.166104,1.49501 -3.22921,2.30231 -2.063106,0.7774 -4.724215,0.7774 z"
              id="logo-s" />
            <path d="m 50.117575,296.07686 q -3.498311,0 -5.770718,-1.4053 -2.242507,-1.40531 -3.049809,-4.18601 l -1.106304,0.4186 q -0.2392,0.0897 -0.358801,0.0897 -0.269101,0 -0.448501,-0.1495 -0.149501,-0.1794 -0.149501,-0.4485 v -2.36211 q 0,-0.3887 0.149501,-0.5681 0.1495,-0.1794 0.508301,-0.3289 l 0.897003,-0.35881 -0.0299,-1.6744 q 0,-0.7774 0.0598,-2.33221 0.149501,-3.94681 2.631209,-6.06972 2.481707,-2.1528 6.66772,-2.1528 3.22921,0 5.471717,1.3455 2.242507,1.3455 3.13951,3.88701 l 1.225904,-0.4784 q 0.239201,-0.0897 0.328901,-0.0897 0.269101,0 0.448501,0.1794 0.179401,0.1495 0.179401,0.4186 v 2.33221 q 0,0.3887 -0.149501,0.5681 -0.1495,0.1794 -0.508301,0.3289 l -0.837203,0.3289 q 0.0299,0.65781 0.0299,1.97341 0,1.76411 -0.0299,2.60131 -0.1495,4.00661 -2.601308,6.06972 -2.451807,2.0631 -6.697621,2.0631 z m 3.318911,-14.23244 q -0.269101,-1.43521 -1.136204,-2.12291 -0.867103,-0.6877 -2.182707,-0.6877 -1.554805,0 -2.481707,0.9568 -0.926903,0.92691 -0.986703,2.87041 -0.0598,0.598 -0.0598,1.64451 z m -3.318911,9.74743 q 1.554805,0 2.481708,-0.9269 0.926903,-0.95681 0.986703,-2.90031 0.0598,-1.46511 0.0299,-2.12291 l -6.936821,2.72091 q 0.2093,1.6445 1.076403,2.45181 0.897003,0.7774 2.362107,0.7774 z"
              id="logo-ø" />
            <path d="m 63.994022,295.77786 q -0.328901,0 -0.568101,-0.2093 -0.209301,-0.2093 -0.209301,-0.5382 V 275.6252 q 0,-0.3289 0.209301,-0.5382 0.2392,-0.2392 0.568101,-0.2392 h 3.378711 q 0.747502,0 1.076403,0.6578 l 5.142816,9.20923 5.172716,-9.20923 q 0.328901,-0.6578 1.076404,-0.6578 h 3.37871 q 0.328901,0 0.538202,0.2392 0.239201,0.2093 0.239201,0.5382 v 19.40516 q 0,0.3289 -0.239201,0.5382 -0.209301,0.2093 -0.538202,0.2093 H 79.42247 q -0.299001,0 -0.538201,-0.2093 -0.209301,-0.2392 -0.209301,-0.5382 v -10.88363 l -3.22921,5.92021 q -0.388701,0.68771 -1.046503,0.68771 H 72.78465 q -0.598002,0 -1.046504,-0.68771 l -3.199309,-5.92021 v 10.88363 q 0,0.3289 -0.239201,0.5382 -0.209301,0.2093 -0.538202,0.2093 z"
              id="logo-m" />
          </g>
        </g>
      </svg>
    </LogoContainer>
  );
}

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }
  ${mediaqueries.tablet`
    svg {
      width: 85%;
      min-width: 240px;
    }
    .Logo__Desktop {
      display: none;
    }
    .Logo__Mobile{
      display: block;
    }
  `}
  ${mediaqueries.phablet`
    svg {
      width: 80%;
      min-width: 200px;
    }
  `}
  ${mediaqueries.phone`
    svg {
      width: 80%;
      min-width: 150px;
    }
  `}
  ${mediaqueries.phone_small`
    svg {
      width: 80%;
      min-width: 90px;
    }
  `}
`;